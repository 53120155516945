* {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  min-height: 200vh;
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.body {
  border: 0;
  margin-top: 0px;


}






.aSearch {
  padding: 0%;
  border: 0%;
  margin: 0%;
  height: 100%;
  min-height: 100vh;
  max-height: 1000vh;
  background: transparent;
  background-color:#222;

}




.secondpage {
  background-color: #58629b;
  border: 0px;
  border-radius: 0px;
  padding-top: 0px;
}

.result {
  padding: 80px;
  width: 100%;
  height: 100%;
}

.rbody {
  /* background-color:#655977;
  
 */

  border: 0px;
  background-color: #40404F;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0px;

  min-height: 100vh;
  padding-left: 15px;
  padding-top: 0px;

}

.result tr td img {
  width: 200px;
  height: 150px;
}


.tableData {
  background-color: #AA98A9;
  font-family: Bahnschrift SemiBold;
  margin: 0px;
  color: black;
  padding: 10px;
  margin-right: 20px;
  padding-top: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}


.tableData p {

  display: flex;
  text-align: left;
  padding-left: 2px;

}

.Searching {
  animation: searcher 2s infinite linear;
  display: flex;
  align-items: center;
  background: transparent;
  border-style: dashed;


}

@keyframes searcher {
  from {
    width: 100%;
    height: 100%;
  }

  to {
    width: 50%;
    height: 50%;


  }

}

.Error {
  font-family: Bahnschrift SemiBold;
  align-items: center;
  align-self: center;
}

.request {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  font-family: Copperplate Gothic Bold;
  border: 0;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background: #58629b;
  cursor: pointer;
}

.request img {
  animation: mover 2s infinite linear;
  border-radius: 50;

  width: 100px;
  height: 65px;
  border-radius: 100%;
  background: transparent;
  flex: 1;
  border: none;

  outline: 1px;
  padding: 24px, 24px;
  font-size: 30px;
  color: #cac7ff
}


.maintable {
  padding-top: 5px;



}

.maintable td {
  padding-bottom: 15px;
}




/* CSS */
.button-86 {

  all: unset;
  width: 50px;
  height: 15px;
  font-size: 14px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 20px;
  height: 10px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}




.tab-img img {
  width: 140px;
  height: 110px;
  border-radius: 10px;
}

.imug:hover {

  filter: brightness(150%);

  border-color: lightcoral;
  filter: grayscale(25%);

  border-style: groove;


}

.tab-img {
  position: relative;
  width: 150px;
  height: 110px;
  color: #fff;
  background: transparent;
  overflow: hidden;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1em;
}



span {
  position: absolute;
  border-radius: 100vmax;
}

.top {
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: linear-gradient(90deg,
      transparent 50%,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49));
}

.bottom {
  right: 0;
  bottom: 0;
  height: 5px;
  background: linear-gradient(90deg,
      rgb(57, 255, 20),
      rgba(57, 255, 20, 0.5),
      transparent 50%);
}

.right {
  top: 0;
  right: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      transparent 30%,
      rgba(0, 255, 255, 0.5),
      rgb(0, 255, 255));
}

.left {
  left: 0;
  bottom: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      rgb(255, 255, 113),
      rgba(255, 255, 113, 0.5),
      transparent 70%);
}

.top {
  animation: animateTop 3s ease-in-out infinite;
}

.bottom {
  animation: animateBottom 3s ease-in-out infinite;
}

.right {
  animation: animateRight 3s ease-in-out infinite;
}

.left {
  animation: animateLeft 3s ease-in-out infinite;
}

@keyframes animateTop {
  25% {
    width: 100%;
    opacity: 1;
  }

  30%,
  100% {
    opacity: 0;
  }
}

@keyframes animateBottom {

  0%,
  50% {
    opacity: 0;
    width: 0;
  }

  75% {
    opacity: 1;
    width: 100%;
  }

  76%,
  100% {
    opacity: 0;
  }
}

@keyframes animateRight {

  0%,
  25% {
    opacity: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  55%,
  100% {
    height: 100%;
    opacity: 0;
  }
}

@keyframes animateLeft {

  0%,
  75% {
    opacity: 0;
    bottom: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.tab-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110;
  background-color: #282c34;
}

.reqBox {
  display: flex;
  align-items: left;
  align-self: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;

}

/**/

.assist {
  margin: 0px;
  border: 0;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  padding-top: 20px;
  font-size: x-large;
  color: whitesmoke;
  font-family: Gadugi;
  border-radius: 20%;
  border-color: wheat;
  border-style: dashed;
}

.user-list {

  width: 150px;
  margin: 0px;
  padding: 5px;
  font-size: xx-small;
  font-family: Andale Mono, monospace
}

.user-list Link {

  text-decoration: none !important;
  padding-top: 0px;
  color: #FFF !important;


}

.user-list li {
  height: 50px;

}

.user-list Link:hover {

  color: #FFF !important;
}

.avatar {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  padding-top: 5px;
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info {
  border: solid black;
  height: 55px;
  width: 100px;
  padding-top: 0;
  color: darkslategrey;
  border-radius: 30%;
  border-color: black;

  background-color: lightpink;
  margin: 0;
}

.user-info a {
  display: flex;
  align-items: center;
  justify-content: center;

}

/* ChatRoom.css */
/* ChatRoom.css */

.chat-room {
  display: flex;
  flex-direction: column;
  height: 800px;
  background-color: #222;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: scroll;
  padding-left: 0;
  margin-left: 0;
  border-left: 0;
  padding-bottom: 15px;
  animation: slide-up 0.5s ease;
}

@keyframes slide-up {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.chat-message {
  background-color: #333;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 20px;
  font-size: 14px;
  max-width: 70%;
  align-self: flex-start;
  color: white;
}

.chat-message:last-child {
  margin-bottom: 0;
}

.chat-form {
  display: flex;
  align-items: center;
  margin-top: 10px;
  animation: slide-up 0.5s ease;
}

.chat-input {
  flex-grow: 1;
  padding: 8px;
  border: none;
  border-radius: 20px;
  background-color: #444;
  color: #fff;
  font-size: 14px;
  outline: none;
}

.chat-button {
  padding: 8px 12px;
  margin-left: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-button:hover {
  background-color: #45a049;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.new-message-animation {
  animation: pulse 0.5s ease;
}