.Title h1 {
    color: white;
    font-family: "Apple Chancery", monospace;
    font-size: xx-large;
  }
  
  .Title h1:hover {
    color: lightsteelblue;
  
  }

  .Title {
    z-index: 10000;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-position: center;
    align-items: center;
    font-size: xx-large;
    font-family: "Apple Chancery", monospace;
    color: whitesmoke;
    min-height: 5vh;
    padding-top: 30px;
    background-color: black;
    padding-bottom: 15px;
    padding-left: 80px;
    overflow: hidden;
  }

  
.signbox {
    position: absolute;
    top: 5%;
    right: 30px;
    transform: translateY(-50%);
    font-size: medium;

  }

  
 


  
  /* CSS */
  .button-81 {
    padding-right: 20px;
    width: 150px;
    height: 40px;
    background-color:lavender;
    border: 0 solid gray;
    border-radius:40px;
    box-sizing: border-box;
    color: #0d172a;
    cursor: pointer;
    display: inline-block;
    font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1;
    padding-top: 10px;
    padding-left: 12px;
    text-align: center;
    text-decoration: none #0d172a solid;
    text-decoration-thickness: auto;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-81:hover {
    background-color: #1e293b;
    color: #fff;
  }
  
  @media (min-width: 768px) {
    .button-81 {
      font-size: 1.125rem;
      padding-bottom: 20px;
    }
  }